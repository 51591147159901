import { instance } from "./firebase-app-instance";

export function firebaseAnalyticsService() {
	return instance.analytics()
}

export function initializeFirebaseAnalytics() {
	return firebaseAnalyticsService()
}

export function dispatchStartMenuEvent(selected_menu_name) {
	return firebaseAnalyticsService().logEvent('start_menu', { selected_menu_name })
}

export function dispatchBackToListMenu(current_menu_name) {
	return firebaseAnalyticsService().logEvent('back_to_list_menu', { current_menu_name })
}

export function dispatchSelectItem(name_item, price_item, rating_item) {
	return firebaseAnalyticsService().logEvent('select_item', { name_item, price_item, rating_item })
}

export function dispatchStartRating(name_item, previous_rating_item) {
	return firebaseAnalyticsService().logEvent('start_rating', { name_item, previous_rating_item })
}

export function dispatchCloseRating(selected_stars) {
	return firebaseAnalyticsService().logEvent('close_rating', { selected_stars })
}

export function dispatchApplyRating(selected_stars) {
	return firebaseAnalyticsService().logEvent('apply_rating', { selected_stars })
}

export function dispatchStartAddToList(name_item, rating_item, price_item) {
	return firebaseAnalyticsService().logEvent('start_add_to_list', { name_item, rating_item, price_item })
}

export function dispatchCloseItem(name_item, rating_item, price_item) {
	return firebaseAnalyticsService().logEvent('close_item', { name_item, rating_item, price_item })
}

export function dispatchAddToList(name_item, selected_quantity, price_item, rating_item) {
	return firebaseAnalyticsService().logEvent('add_to_list', { name_item, selected_quantity, price_item, rating_item })
}

export function dispatchCloseAddItem(name_item, selected_quantity, price_item, rating_item) {
	return firebaseAnalyticsService().logEvent('close_add_item', { name_item, selected_quantity, price_item, rating_item })
}

export function dispatchStartItemList(current_quantity_item, current_list_name, list_quantity_item, current_total_price) {
	return firebaseAnalyticsService().logEvent('start_clean_all_list', { current_quantity_item, current_list_name, list_quantity_item, current_total_price })
}

export function dispatchCleanAllList(current_quantity_item, current_list_name, list_quantity_item, current_total_price) {
	return firebaseAnalyticsService().logEvent('clean_all_list', { current_quantity_item, current_list_name, list_quantity_item, current_total_price })
}

export function dispatchCancelCleanAllList(current_quantity_item, current_list_name, list_quantity_item, current_total_price) {
	return firebaseAnalyticsService().logEvent('cancel_clean_all_list', { current_quantity_item, current_list_name, list_quantity_item, current_total_price })
}

export function dispatchStartCleanAllList(current_quantity_item, current_list_name, list_quantity_item, current_total_price) {
	return firebaseAnalyticsService().logEvent('start_item_list', { current_quantity_item, current_list_name, list_quantity_item, current_total_price })
}

export function dispatchCloseItemList(current_quantity_item, current_list_name, list_quantity_item, current_total_price) {
	return firebaseAnalyticsService().logEvent('close_item_list', { current_quantity_item, current_list_name, list_quantity_item, current_total_price })
}

export function dispatchMatchItemSelect(name_item, price_item, original_name_item, original_price_item, Original_rating_item) {
	return firebaseAnalyticsService().logEvent('match_item_select', { name_item, price_item, original_name_item, original_price_item, Original_rating_item })
}


export function dispatchStartDeleteItem(name_item, selected_quantity) {
	return firebaseAnalyticsService().logEvent('start_delete_item', { name_item, selected_quantity })
}

export function dispatchDeleteItem(name_item, selected_quantity) {
	return firebaseAnalyticsService().logEvent('delete_item', { name_item, selected_quantity })
}

export function dispatchAddQuantity(item_quantity) {
	return firebaseAnalyticsService().logEvent('add_quantity', { item_quantity })
}

export function dispatchDecreaseQuantity(item_quantity) {
	return firebaseAnalyticsService().logEvent('decrease_quantity', { item_quantity })
}

export function dispatchSelectSection(section_name) {
	return firebaseAnalyticsService().logEvent('select_section', { section_name })
}
