import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
	row: {
		width: "100%",
		overflow: "hidden"
	},
	component: {
		width: "100%",
		maxWidth: "calc(277px + 1.5rem)",
		margin: "0 auto"
	}
});
