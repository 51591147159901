import { useCallback } from 'react'
import { Container } from '../../components/container'
import { Header } from '../../components/header'
import { useRestaurantContext } from "../../store/restaurant"

import { CleanCartAction, CartItem } from './components'
import { useStyles } from './cart-screen.styles'
import { useCartContext } from '../../store/cart'
import { dispatchCloseItemList } from '../../services/firebase'
import { getCartQuantity, getCartItemNames, getCartQuantities, getCartPrices } from '../../utilities/cart-utilities'
import { useAppActions } from '../../store/app'

export function CartScreen() {
	const restaurant = useRestaurantContext()
	const cart = useCartContext()
	const classes = useStyles()
	const appActions = useAppActions()

	if (restaurant.state === null) {
		throw new Error('Restaurant context shoul be not empty')
	}

	if (cart.state.length === 0) {
		appActions.getOutOfTheCart()
	}

	const handleOnBack = useCallback(() => {
		dispatchCloseItemList(getCartQuantity(cart.state), getCartItemNames(cart.state), getCartQuantities(cart.state), getCartPrices(cart.state))
		appActions.getOutOfTheCart()
	}, [appActions, cart.state])

	return (
		<Container ultra>
			<div className={classes.sticky}>
				<Header renderOnRight={<CleanCartAction />} onBack={handleOnBack} />
			</div>
			<div className={classes.component}>
				<div className={classes.header}>
					<h1 className={classes.title}>Sua lista</h1>
					<p>Quando estiver satisfeito com sua lista, chame o garçom e faça seu pedido. </p>
				</div>
				{cart.state.map(({item, quantity}) => <CartItem item={item} quantity={quantity} key={item.id} />)}
			</div>
		</Container>
	)
}
