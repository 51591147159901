import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
	sections: {},
	stickyHeader: {
		position: 'fixed',
		backgroundColor: 'white',
		zIndex: 200,
		width: 414,
		maxWidth: '100%',
		'@media screen and (max-width: 640px)': {
			top: 0
		}
	},
	filtersRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		borderBottom: '1px solid #cccccc',
	},
	content: {
		marginTop: 123,
		minHeight: 'calc(100% - 124px)',
		display: "flex",
		flexDirection: "column",
		backgroundColor: "var(--color-secondary-light)"
	},
});
