import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
	component: {
		height: "4.625rem",
		flex: 1,
		width: "100%",
		padding: "0.5rem 1rem",
		display: "flex",
		alignItems: "flex-end",
		justifyContent: "space-between",
		backgroundColor: "transparent",

		position: ({ absolutely }) => (absolutely ? "absolute" : "static"),
		left: 0,
		right: 0,
		top: 0
	},
	right: {
		height: '2rem',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end'
	}
});
