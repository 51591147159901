import { useReducer } from "react";

import { initialState, restaurantStore } from "./restaurant-store";

const { Provider } = restaurantStore;

export const RestaurantProvider = ({ children }) => {
	const [state, dispatch] = useReducer((state, { type, value }) => {
		switch(type) {
			case 'CREATE':
				return value
			case 'action description':
				return null
			default:
				throw new Error('Store action should be a type')
		}
	}, initialState)

	return <Provider value={{ state, dispatch }}>{children}</Provider>;
}

