import { useEffect } from 'react'
import { useMenuContext } from '../../store/menu'

import { RestaurantMenuPremium } from './components/restaurant-menu-premium'

export function RestaurantMenuScreen() {
	const menu = useMenuContext()

	useEffect(() => {
		document.getElementById('app-container').scrollTo(0, 0);
	}, [menu.state])

	if (menu.state === null) {
		throw new Error('Restaurant menu screen should be a selected menu')
	}

	switch(menu.state.menuType) {
		case "premium":
			return <RestaurantMenuPremium />
		default:
			throw new Error('Restaurant menu should be a type')
	}
}
