import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
	component: {
		"& .MuiTabs-indicator": {
			display: "none"
		},
		"& .MuiTabs-flexContainer": {
			"overflowX": "scroll",
			"&::-webkit-scrollbar": {
				display: "none"
			},
			"& .MuiButtonBase-root.MuiTab-root": {
				"textTransform": "inherit",
				"fontWeight": "600",
				"fontSize": "1rem",
				"color": "#888888",
				position: 'relative',
				"&.Mui-selected": {
					color: "var(--color-black)",
					'&::after': {
						position: 'absolute',
						content: '""',
						display: 'block',
						width: 4,
						height: 4,
						backgroundColor: 'var(--color-black)',
						left: 0,
						right: 0,
						bottom: 8,
						borderRadius: 4,
						margin: 'auto'
					}
				}
			}
		}
	}
});
