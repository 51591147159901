
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { Container } from '../../components/container'
import { Header } from "../../components/header";
import { ReviewNowAction } from '../../components/review-now-action'
import { ReviewFlag } from '../../components/review-flag'
import { RestaurantMenuPremiumFeaturedGroup } from '../../components/restaurant/menu/premium/featured-group'
import { toCurrencyString } from '../../formatters';
import { useMenuItemActions, useMenuItemContext } from '../../store/menu-item'
import { toSectionsString } from '../../utilities/menu-item';

import { MenuItemTime, MenuItemFilterLabels, AddToCart } from './components'
import { useStyles } from './menu-item-screen.styles'

export function MenuItemScreen({ onAdded }) {
	const containerEl = document.getElementById('app-container');
	const classes = useStyles()
	const { state } = useMenuItemContext()
	const { backToPreviousMenuItem } = useMenuItemActions()
	const [scrolled, changeScrolled] = useState(false)

	useEffect(() => {
		containerEl.onscroll = e => {
			changeScrolled(e.target.scrollTop > 0)
			return e
		}
	}, [containerEl])

	const handleOnBack = useCallback(() => {
		backToPreviousMenuItem()
	}, [backToPreviousMenuItem])

	if (state.current === null) {
		throw new Error('Menu item screen should be not a empty context')
	}

	const { id, averageReview, pictureUrl, sections, name, description, price, suggestedItems } = state.current

	return (
		<Container withCartFeedBack ultra>
			<div className={classNames(classes.stickyHeader, {
				[classes.headerWithoutImage]: !pictureUrl,
				[classes.scrolled]: scrolled
			})}>
				<Header onBack={handleOnBack} renderOnRight={<AddToCart onAdded={onAdded} />} />
			</div>
			<div className={classNames(classes.cover, { [classes.withoutImage]: !pictureUrl })} style={{ backgroundImage: `url(${pictureUrl})` }}>
				<ReviewNowAction itemId={id} className={classes.reviewAction} />
				<ReviewFlag value={averageReview} />
			</div>
			<div className={classes.content}>
				<p className={classes.text}>{toSectionsString(sections)}</p>
				<h2 className={classes.title}>{name}</h2>
				<MenuItemTime />
				<p className={classNames(classes.description, classes.text)}>{description}</p>
				<MenuItemFilterLabels />
				<h4 className={classes.price}>{toCurrencyString(price)}</h4>
			</div>
			{!!suggestedItems && <RestaurantMenuPremiumFeaturedGroup suggestions section={suggestedItems} />}

		</Container>
	)
}
