import { makeStyles } from "@material-ui/styles"
import { useMenuItemContext } from "../../../store/menu-item"

const useStyles = makeStyles({
	component: {
		color: 'var(--color-secondary)',
		fontSize: '0.6875rem',
		fontWeight: '400',
		display: 'flex',
		alignItems: 'center',
		'& span': {
			marginLeft: '0.25rem'
		}
	}
})

export function MenuItemTime() {
	const classes = useStyles()
	const { state } = useMenuItemContext()
	const { timeFoPreparation } = state.current

	if (!timeFoPreparation) {
		return null
	}

	return (
		<p className={classes.component}>
			<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M6.49999 13C10.052 13 13.0081 10.052 13.0081 6.5C13.0081 2.94796 10.044 0 6.49194 0C2.94795 0 0 2.94796 0 6.5C0 10.052 2.95602 13 6.49999 13ZM6.49999 11.7757C3.5762 11.7757 1.2404 9.43184 1.2404 6.5C1.2404 3.56816 3.56815 1.22429 6.49194 1.22429C9.42378 1.22429 11.7677 3.56816 11.7757 6.5C11.7838 9.43184 9.43185 11.7757 6.49999 11.7757ZM3.27819 7.21685H6.49194C6.77385 7.21685 6.99133 6.99937 6.99133 6.72553V2.57745C6.99133 2.29554 6.77385 2.07807 6.49194 2.07807C6.21808 2.07807 6.00062 2.29554 6.00062 2.57745V6.22614H3.27819C2.99628 6.22614 2.78687 6.44362 2.78687 6.72553C2.78687 6.99937 2.99628 7.21685 3.27819 7.21685Z" fill="#CCCCCC"/>
			</svg>
			<span>{timeFoPreparation} min</span>
		</p>
	)
}
