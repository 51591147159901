export function fallbackAmountFormatter(value) {
	const stringfied = `${Math.abs(value)}`;

	const integerPart = stringfied.slice(0, stringfied.length - 2) || "0";
	const fractionalPart = `0${stringfied}`.slice(-2);

	return value < 0 ? `-${integerPart},${fractionalPart}` : `${integerPart},${fractionalPart}`;
}

const LOCALE = "pt-BR";

const supportsLocale = Intl.NumberFormat.supportedLocalesOf(LOCALE, {
	localeMatcher: "lookup"
}).includes(LOCALE);

const localeNumberFormatter = new Intl.NumberFormat(LOCALE, {
	style: "decimal",
	minimumIntegerDigits: 1,
	minimumFractionDigits: 2,
	maximumFractionDigits: 2
});

const amountFormatter = supportsLocale
	? value => localeNumberFormatter.format(value)
	: value => fallbackAmountFormatter(value);

export function toAmountString(value) {
	return amountFormatter(value);
}

export function toCurrencyString(value) {
	return `R$ ${amountFormatter(value)}`;
}
