import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
	avatar: {
	  backgroundColor: 'white',
	  color: 'black',
	},
	header: {
		boxShadow: '0px 0.5px 0px rgba(0, 0, 0, 0.3)',
		display: 'flex',
		alignItems: 'center',
		height: 55
	},
	title: {
		paddingRight: 64,
		flex: '1',
		textAlign: 'center',
		fontWeight: '600',
		fontSize: '1.125rem'
	},
	content: {
		padding: '1rem',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	}
  });
