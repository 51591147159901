import { useState, useMemo } from 'react'
import classNames from 'classnames'
import { Button } from '@material-ui/core'
import { makeStyles } from "@material-ui/styles"
import { IconThreeDots } from '../../../components/icons'
import { toSectionsString } from "../../../utilities/menu-item"

import { ChangeItemQuantity } from './change-item-quantity'
import { useCartActions, useCartContext } from '../../../store/cart'
import { dispatchStartDeleteItem, dispatchDeleteItem } from '../../../services/firebase'
import { useCallback } from 'react'

export function CartItem({ item }) {
	const classes = useStyles()
	const { remove } = useCartActions()
	const cart = useCartContext()
	const [showDelete, changeShowDelete] = useState(false)

	const { quantity } = useMemo(() => cart.state.find(i => i.item === item), [cart.state, item])

	const handleOnShowDelete = useCallback(() => {
		dispatchStartDeleteItem(item.name, quantity)
		changeShowDelete(!showDelete)
	}, [item.name, quantity, showDelete])

	const handleOnRemoveItem = useCallback(() => {
		dispatchDeleteItem(item.name, quantity)
		remove(item.id)
	}, [item.id, item.name, quantity, remove])

	return (
		<div className={classNames(classes.component, { [classes.showDelete]: showDelete })}>
			{item.pictureUrl ? <div className={classes.cover} style={{ backgroundImage: `url(${item.pictureUrl})` }} /> : null}
			<div className={classes.content}>
				<p className={classes.sections}>{toSectionsString(item.sections)}</p>
				<p className={classes.title}>{item.name}</p>
				<ChangeItemQuantity itemId={item.id} quantity={quantity} />
			</div>
			<Button onClick={handleOnShowDelete} className={classes.action}><IconThreeDots /></Button>
			<Button onClick={handleOnRemoveItem} className={classNames(classes.delete, { [classes.deleteVisible]: showDelete })}>Excluir</Button>
		</div>
	)
}

const useStyles = makeStyles({
	component: {
		position: 'relative',
		backgroundColor: 'white',
		display: 'flex',
		alignItems: 'flex-start',
		padding: '1rem',
		transition: 'all .2s ease-in-out',
		'& + &': {
			marginTop: 2
		}
	},
	showDelete: {
		transform: 'translateX(-66px)'
	},
	cover: {
		marginRight: '0.875rem',
		height: 90,
		width: 80,
		borderRadius: '0.875rem',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat'
	},
	content: {
		flex: '1'
	},
	action: {
		minWidth: 'fit-content !important'
	},
	sections: {
		fontSize: 11,
		fontWeight: '600',
		color: 'var(--color-primary)',
		lineHeight: '1.1',
		marginBottom: 2
	},
	title: {
		fontSize: 15,
		fontWeight: '400',
		letterSpacing: '-0.41px',
		lineHeight: '1.4 '
	},
	delete: {
		padding: '0.95rem',
		borderRadius: '0 !important',
		color: 'white !important',
		textTransform: 'inherit !important',
		fontSize: '17px !important',
		letterSpacing: '-0.41px !important',
		backgroundColor: '#ff0000 !important',
		position: 'absolute !important',
		height: '100%',
		top: 0,
		right: 0,
		transform: 'translateX(102%)'
	},
})
