import { useEffect, useState } from "react";

import { useRestaurantActions } from "../../../store/restaurant";
import { getAppStructured } from "../../../services/restaurant-service";

export function useRestaurantScreen(id) {
	const { setRestaurant } = useRestaurantActions()
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		(async () => {
			try {
				const app = await getAppStructured(id)
				await setRestaurant(app)
				setLoading(false)
			} catch (err) {
				setLoading(false)
				throw new Error(err)
			}
		})()

	}, [id, setRestaurant]);

	return { loading };
}
