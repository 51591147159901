import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import classNames from 'classnames';

import { useStyles } from './dialog.styles';

export function SimpleDialog({ onClose, open, title, children, isNotSimple = false }) {
	const classes = useStyles();

	return (
	  <Dialog className={classNames({'__simple-dialog': !isNotSimple, '__not-simple-dialog': isNotSimple})} onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
		{isNotSimple ? null : (
			<div className={classes.header}>
				<Button onClick={onClose}>
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM5.89357 7.19034C5.53548 6.83224 5.53548 6.25166 5.89357 5.89357C6.25166 5.53548 6.83224 5.53548 7.19034 5.89357L10 8.70323L12.8097 5.89357C13.1678 5.53548 13.7483 5.53548 14.1064 5.89357C14.4645 6.25166 14.4645 6.83224 14.1064 7.19034L11.2968 10L14.1064 12.8097C14.4645 13.1678 14.4645 13.7483 14.1064 14.1064C13.7483 14.4645 13.1678 14.4645 12.8097 14.1064L10 11.2968L7.19034 14.1064C6.83224 14.4645 6.25166 14.4645 5.89357 14.1064C5.53548 13.7483 5.53548 13.1678 5.89357 12.8097L8.70323 10L5.89357 7.19034Z" fill="#888888"/>
					</svg>
				</Button>
				<p className={classes.title}>{title}</p>
			</div>
		)}
		<div className={classes.content}>
			{children}
		</div>
	  </Dialog>
	);
  }
