import { useCallback } from "react"
import { dispatchStartMenuEvent, dispatchBackToListMenu } from '../../services/firebase'
import { useCartActions } from "../cart"
import { useRestaurantContext } from "../restaurant"
import { useMenuContext } from "./menu-store-hook"

export function useMenuActions() {
	const restaurant = useRestaurantContext()
	const menu = useMenuContext()
	const CartActions = useCartActions()
	const { dispatch } = useMenuContext()

	if (restaurant.state === null) {
		throw new Error('Restaurant state should by not empty when you try dispatch a menu action')
	}

	const setMenu = useCallback(async (id) => {
		await CartActions.clean()
		const value = id === null ? null : restaurant.state.menus.find(m => m.id === id)

		if (value !== null) {
			dispatchStartMenuEvent(value.name)
		}

		if (value === null && menu !== null) {
			dispatchBackToListMenu(menu.state.name)
		}

		return await dispatch({
			type: 'CREATE',
			value
		})
	}, [CartActions, dispatch, menu, restaurant.state.menus])

	return {
		setMenu
	}
}
