import { makeStyles } from "@material-ui/styles";

import CoverImage from "./images/cover.png";

export const useStyles = makeStyles({
	content: {
		padding: "1rem"
	},
	cover: {
		height: 277,
		background: "",
		backgroundColor: "var(--color-secondary-light)",
		backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%), url(${CoverImage})`,
		backgroundSize: "cover",
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat"
	},
	title: {
		fontWeight: "bold",
		fontSize: "1.5rem",
		lineHeight: "1.25",
		marginBottom: "1rem"
	},
	text: {
		"color": "var(--color-secondary)",
		"fontSize": "1rem",
		"lineHeight": "1.5",

		"& + &": {
			marginTop: "1rem"
		}
	}
});
