import { Button } from '@material-ui/core'

import { useStyles } from './action-back.styles'

export function ActionBack({ onClick }) {
	const classes = useStyles()

	return (
		<Button className={classes.component} onClick={onClick}>
			<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path className={classes.path} d="M0 7.19805C0 7.43997 0.109965 7.6709 0.307902 7.85784L5.98213 13.5211C6.18007 13.708 6.40001 13.796 6.63094 13.796C7.11478 13.796 7.48867 13.4331 7.48867 12.9383C7.48867 12.7073 7.41169 12.4764 7.24675 12.3224L5.72922 10.7499L2.5952 7.93482L4.73951 8.08877H15.1203C15.6371 8.08877 16 7.71488 16 7.19805C16 6.68121 15.6371 6.30732 15.1203 6.30732H4.73951L2.60619 6.46127L5.72922 3.64615L7.24675 2.07364C7.41169 1.91969 7.48867 1.68876 7.48867 1.45783C7.48867 0.962978 7.11478 0.600098 6.63094 0.600098C6.40001 0.600098 6.18007 0.688064 5.98213 0.87501L0.307902 6.53825C0.109965 6.72519 0 6.95613 0 7.19805Z" />
			</svg>
		</Button>
	)
}
