import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
	component: {
		width: "100%",
		maxWidth: "calc(277px + 1.5rem)",
		marginLeft: "0.775rem",
		marginRight: "0.775rem",
		paddingTop: "1.5rem",
		paddingBottom: "1.5rem"
	},
	cover: {
		height: 407,
		backgroundSize: "cover",
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		borderRadius: "0.75rem",
		boxShadow:
			"0px 30px 45px -12px rgba(50, 50, 93, 0.25), 0px 18px 36px -18px rgba(0, 0, 0, 0.3)",

		borderLeft: "0.75rem solid var(--color-primary)",
		backgroundColor: "var(--color-disabled)",
		backgroundImage: ({ image }) =>
			`linear-gradient(179.95deg, rgba(34, 34, 34, 0) 50.03%, rgba(34, 34, 34, 0.7) 85.91%), url(${image})`
	},
	chefsclubCover: {
		"height": 407,
		"backgroundSize": "cover",
		"backgroundPosition": "center",
		"backgroundRepeat": "no-repeat",
		"borderRadius": "0.75rem",
		"boxShadow":
			"0px 30px 45px -12px rgba(50, 50, 93, 0.25), 0px 18px 36px -18px rgba(0, 0, 0, 0.3)",

		"borderLeft": "0.75rem solid var(--color-black-light)",
		"backgroundColor": "var(--color-black)",

		"display": "flex",
		"alignItems": "center",
		"justifyContent": "center",

		"& svg": {
			maxWidth: "100%",
			transform: "translateX(-0.375rem)"
		}
	},
	label: {
		fontSize: "1.25rem",
		fontWeight: "600",
		lineHeight: "1.25",
		textAlign: "center",
		marginTop: "1.5rem"
	}
});
