import { useCallback } from "react"
import { useRestaurantContext } from "../restaurant"
import { useMenuContext } from '../menu'
import { useMenuItemContext } from "./menu-item-store-hook"
import { isObject } from "../../utilities/objectUtils"
import { dispatchSelectItem } from "../../services/firebase"

export function useMenuItemActions() {
	const restaurant = useRestaurantContext()
	const menu = useMenuContext()
	const { state, dispatch } = useMenuItemContext()

	if (restaurant.state === null) {
		throw new Error('Restaurant state should by not empty when you try dispatch a menu action')
	}

	if (menu.state === null) {
		throw new Error('Restaurant menu state should by not empty when you try dispatch a menu item action')
	}

	const clean = useCallback(() => {
		return dispatch({
			type: 'CLEAN'
		})
	}, [dispatch])

	const backToPreviousMenuItem = useCallback(() => {
		if (isObject(state.previous)) {
			return dispatch({
				type: 'BACK'
			})
		}

		return dispatch({
			type: 'CLEAN'
		})
	}, [dispatch, state.previous])

	const setMenuItem = useCallback((value) => {
		dispatchSelectItem(value.name, value.price, value.averageReview)
		return dispatch({
			type: 'CREATE',
			value
		})
	}, [dispatch])

	return {
		clean,
		setMenuItem,
		backToPreviousMenuItem
	}
}
