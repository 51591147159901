import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
	component: {
		marginLeft: 16,
		marginRight: 16,
		borderRadius: "0.75rem",
		overflow: "hidden",
		display: "flex",
		backgroundColor: "var(--color-white)",
		boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.08)",
		width: "100%",
		cursor: 'pointer'
	},
	cover: {
		minHeight: 112,
		width: 112,
		minWidth: 112,
		backgroundSize: "cover",
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat"
	},
	content: {
		padding: "1rem"
	},
	title: {
		color: "var(--color-primary)",
		fontSize: "1.125rem",
		fontWeight: "700",
		lineHeight: "1.4",
		letterSpacing: "-0.41px"
	},
	price: {
		color: "var(--color-secondary)",
		fontSize: "0.875rem",
		lineHeight: "1.6",
		letterSpacing: "-0.24px",
		marginTop: "0.5rem"
	},
	suggestion: {
		color: "var(--color-secondary)",
		fontSize: '0.875rem',
		letterSpacing: '-0.24px',
		fontWeight: '500',
		marginBottom: '0.3rem'
	}
});
