import { useCallback } from "react"
import { useMenuActions } from "../store/menu"

export function useAppActions() {
	const { setMenu } = useMenuActions()

	const backToMenuNavigation = useCallback(() => {
		setMenu(null)
	}, [setMenu])

	return {
		backToMenuNavigation
	}
}
