export function getCartQuantity(raw) {
	if (raw === null || !Array.isArray(raw) || raw.length === 0) {
		return 0
	}

	if (raw.length === 1) {
		return raw[0].quantity
	}

	return raw.reduce((a, b) => (a.quantity + b.quantity))
}

export function getCartItemNames(raw) {
	let string = ''

	if (raw === null || !Array.isArray(raw) || raw.length === 0) {
		return string
	}

	raw.map(({ item }, index) => {
		if (index === 0) {
			return string = item.name
		}

		return string = `${string}, ${item.name}`
	})

	return string
}

export function getCartQuantities(raw) {
	let string = ''

	if (raw === null || !Array.isArray(raw) || raw.length === 0) {
		return string
	}

	raw.map(({ quantity }, index) => {
		if (index === 0) {
			return string = quantity
		}

		return string = `${string}, ${quantity}`
	})

	return string
}

export function getCartPrices(raw) {
	let string = ''

	if (raw === null || !Array.isArray(raw) || raw.length === 0) {
		return string
	}

	raw.map(({ item, quantity }, index) => {
		if (index === 0) {
			return string = item.price * quantity
		}

		return string = `${string}, ${item.price * quantity}`
	})

	return string
}
