import { useReducer } from "react";

import { initialState, appStore } from "./app-store";

const { Provider } = appStore;

export const AppProvider = ({ children }) => {
	const [state, dispatch] = useReducer((state, { type, value }) => {
		switch(type) {
			case 'CLEAN':
				return initialState
			case 'SNACKBAR_SET':
				return { ...state, snackbar: value }
			case 'GO_TO_CART':
				return { ...state, onCart: true }
			case 'GET_OUT_OF_THE_CART':
				return { ...state, onCart: false }
			default:
				throw new Error('Store action should be a type')
		}
	}, initialState)

	return <Provider value={{ state, dispatch }}>{children}</Provider>;
}

