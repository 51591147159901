import { useCallback } from "react"

import { useCartContext } from "./cart-store-hook"

export function useCartActions() {
	const { dispatch } = useCartContext()

	const clean = useCallback(() => {
		return dispatch({ type: 'CLEAN' })
	}, [dispatch])

	const remove = useCallback((itemId) => {
		return dispatch({
			type: 'REMOVE_ITEM',
			value: itemId
		})
	}, [dispatch])

	const increment = useCallback((itemId) => {
		return dispatch({
			type: 'INCREMENT',
			value: itemId
		})
	}, [dispatch])

	const decrement = useCallback((itemId) => {
		return dispatch({
			type: 'DECREMENT',
			value: itemId
		})
	}, [dispatch])

	const add = useCallback((value) => {
		return dispatch({
			type: 'ADD_ITEM',
			value
		})
	}, [dispatch])

	return {
		add,
		clean,
		increment,
		decrement,
		remove
	}
}
