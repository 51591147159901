import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
	component: {
		"&.MuiButton-root": {
			"width": "2rem",
			"minWidth": "2rem",
			"height": "2rem",
			"borderRadius": "1rem",
			"backgroundColor": "var(--color-secondary-light)",
			"transition": "all .25s ease-in-out",

			"&:hover": {
				opacity: 0.9,
				backgroundColor: "var(--color-secondary-light)"
			}
		}
	},
	path: {
		fill: "var(--color-secondary)"
	}
});
