import { useCallback } from "react"
import { asSingle } from "../../utilities/objectUtils"
import { useRestaurantContext } from "./restaurant-store-hook"

export function useRestaurantActions() {
	const { dispatch } = useRestaurantContext()


	const setRestaurant = useCallback((value) => {
		return dispatch({ type: 'CREATE', value: asSingle(value) })
	}, [dispatch])

	return {
		setRestaurant
	}
}
