import { useCallback, useState } from "react"
import classNames from "classnames"
import { Button } from "@material-ui/core"

import { useMenuItemContext } from '../../../store/menu-item'
import { SimpleDialog } from '../../../components/dialog'
import { IconAddButton, IconRemoveButton } from '../../../components/icons'

import { useAddToCart } from "./add-to-cart-hook"
import { useStyles } from './add-to-cart.styles'
import { isObject } from "../../../utilities/objectUtils"
import { dispatchAddToList, dispatchCloseItem, dispatchStartAddToList, dispatchCloseAddItem } from "../../../services/firebase"

function Dialog({ open, onClose, onClick, value, changeValue }) {
	const menuItem = useMenuItemContext()
	const classes = useStyles()

	if (!isObject(menuItem.state.current)) {
		throw new Error('Menu item should be not empty')
	}

	return (
		<SimpleDialog open={open} onClose={onClose} title="Selecione a quantidade">
			<div className={classes.component}>
				<div className={classes.content}>
					<p className={classes.label}>{menuItem.state.current.name}</p>
					<div className={classes.form}>
						<Button onClick={() => changeValue(value - 1)} disabled={value === 1}><IconRemoveButton className={classNames(classes.action, { [classes.disabledAction]: value === 1 })} /></Button>
						<span className={classes.value}>{value}</span>
						<Button onClick={() => changeValue(value + 1)}><IconAddButton className={classNames(classes.action)} /></Button>
					</div>
				</div>
				<Button variant="contained" color="primary" fullWidth onClick={() => onClick(value)}>Adicionar</Button>
			</div>
		</SimpleDialog>
	)
}

export function AddToCart({ className, onAdded }) {
	const { submit } = useAddToCart()
	const menuItem = useMenuItemContext()
	const [open, setOpen] = useState(false);
	const [quantity, changeQuantity] = useState(1)

	const handleOpen = useCallback(() => {
		dispatchStartAddToList(menuItem.state.current.name, quantity,  menuItem.state.current.price, menuItem.state.current.averageReview)
		setOpen(true);
	}, [menuItem.state, quantity]);

	const handleClose = useCallback(() => {
		dispatchCloseAddItem(menuItem.state.current.name, quantity,  menuItem.state.current.price, menuItem.state.current.averageReview)
		dispatchCloseItem(menuItem.state.current.name, menuItem.state.current.averageReview, menuItem.state.current.price)
		setOpen(false);
	}, [menuItem.state, quantity]);

	const handleOnClick = useCallback(async (value) => {
		await submit(value)
		dispatchAddToList(menuItem.state.current.name, value,  menuItem.state.current.price)
		onAdded()
		handleClose()
	}, [handleClose, menuItem.state, onAdded, submit])

	return (
		<>
			<Dialog open={open} onClose={handleClose} onClick={handleOnClick} value={quantity} changeValue={changeQuantity} />
			<Button size="small" onClick={handleOpen} className={classNames("__cart-action", className)}>Adicionar à lista</Button>
		</>
	)
}
