export function toSectionsString(raw) {
	let response = ''

	if (Array.isArray(raw)) {
		raw.map((s, i) => {
			response += i === 0 ? s.name : `, ${s.name}`
			return s
		})
	}

	return response
}
