import { Container } from "../../components/container";
import { Header } from "../../components/header";
import { useStyles } from "./about-chefsclub-screen.styles";

export function AboutChefsClubScreen({ onBack }) {
	const classes = useStyles()

	return (
		<Container>
			<Header absolutely onBack={onBack} />
			<div className={classes.cover}></div>
			<article className={classes.content}>
				<h2 className={classes.title}>Os melhores restaurantes estão aqui</h2>
				<p className={classes.text}>
					A maior plataforma para comer fora de casa e economizar muuito!
					<br/>
					Com o ChefsClub você pode ganhar até 50% de desconto em mais de 2.500 restaurantes pelo Brasil, além de fazer reservas, descobrir novos restaurantes, avaliar seus estabelecimentos favoritos, registrar sua economia em cada visita e muito mais.
					<br/>
					Seja assinante e aproveite os benefícios exclusivos agora mesmo.
				</p>
			</article>
		</Container>
	)
}
