import { clientService } from "./service-client";
import { RestaurantStructuredMapper } from "./restaurant-service-mapper";

const restaurantClient = clientService(process.env.REACT_APP_API_URL);

export async function getAppStructured(id) {
	const response = await restaurantClient.get(`/restaurant/${id}/getAppStructure`);

	if (response.status !== 200) {
		return false;
	}

	return RestaurantStructuredMapper(response.data);
}

export async function setItemReview(restaurantId, itemId, evaluation) {
	const response = await restaurantClient.post(`/restaurant/${restaurantId}/item/${itemId}/review`, { itemId, evaluation })

	if (response.status !== 201) {
		return false
	}

	return response.data
}
