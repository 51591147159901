const itemFilters = [{
	label: 'vegano',
	key: 'isVegan',
}, {
	label: 'vegetariano',
	key: 'isVegetarian'
}, {
	label: 'sem glúten',
	key: 'glutenFree'
}]

export function RestaurantStructuredMapper(raw) {
	const result = raw;
	result.menus = result.menus
		.filter(m => m.menuSections !== null && m.menuSections.length > 0)
		.sort((a, b) => (a.order > b.order))
		.map(m => {
			return {
				...m,
				itemFilters,
				menuType: m.menuType[0].type,
				menuSections: m.menuSections.map((m, i) => ({ ...m, index: i })).filter(s => s.items !== null && s.items.length > 0)
			};
		});
	return result;
}
