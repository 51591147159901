import { Button, Link, DialogActions } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useCallback, useState } from 'react'
import { IconHeaderDelete } from '../../../components/icons'
import { SimpleDialog } from '../../../components/dialog'
import { useCartActions, useCartContext } from '../../../store/cart'
import { dispatchCancelCleanAllList, dispatchCleanAllList, dispatchStartCleanAllList } from '../../../services/firebase'
import { getCartItemNames, getCartPrices, getCartQuantities, getCartQuantity } from '../../../utilities/cart-utilities'

const useStyles = makeStyles({
	button: {
		minWidth: 'fit-content !important',
		borderRadius: '2rem !important'
	},
	title: {
		marginTop: '1rem',
		fontWeight: '700',
		fontSize: 17,
		lineHeight: '1.4',
		letterSpacing: '-0.41px',
		marginBottom: '0.5rem'
	},
	text: {
		fontSize: 14,
		letterSpacing: '-0.08px',
		lineHeight: '1.5'
	},
	close: {
		float: 'right',
		minWidth: 'fit-content !important',
		borderRadius: '2rem !important'
	}
})


function Dialog({ open, onClose, onClick }) {
	const classes = useStyles()

	return (
		<SimpleDialog open={open} onClose={onClose} isNotSimple>
			<div className={classes.component}>
				<Button onClick={onClose} className={classes.close}>
					<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1.2422 11.1563C0.914071 11.4844 0.898446 12.0703 1.25001 12.4141C1.59376 12.7578 2.1797 12.75 2.50782 12.4219L7.28907 7.63281L12.0781 12.4219C12.4141 12.7578 12.9922 12.7578 13.3359 12.4141C13.6719 12.0625 13.6797 11.4922 13.3359 11.1563L8.5547 6.36719L13.3359 1.58594C13.6797 1.25 13.6797 0.671876 13.3359 0.328126C12.9844 -0.00781173 12.4141 -0.0156242 12.0781 0.320313L7.28907 5.10938L2.50782 0.320313C2.1797 -0.00781173 1.58595 -0.0234367 1.25001 0.328126C0.906258 0.671876 0.914071 1.25781 1.2422 1.58594L6.03126 6.36719L1.2422 11.1563Z" fill="black"/>
					</svg>
				</Button>
				<h4 className={classes.title}>Limpar sua lista?</h4>
				<p className={classes.text}>Esses itens serão excluídos permanentemente</p>
				<DialogActions>
					<Link onClick={onClose} color="primary">
						Cancelar
					</Link>
					<Button onClick={onClick} color="primary" variant="contained" autoFocus>
						Excluir
					</Button>
				</DialogActions>
			</div>
		</SimpleDialog>
	)
}

export function CleanCartAction() {
	const classes = useStyles()
	const [open, setOpen] = useState(false);
	const { clean } = useCartActions()
	const cart = useCartContext()

	const handleOpen = useCallback(() => {
		setOpen(true);
		dispatchStartCleanAllList(getCartQuantity(cart.state), getCartItemNames(cart.state), getCartQuantities(cart.state), getCartPrices(cart.state))
	}, [cart.state]);

	const handleClose = useCallback(() => {
		setOpen(false);
		dispatchCancelCleanAllList(getCartQuantity(cart.state), getCartItemNames(cart.state), getCartQuantities(cart.state), getCartPrices(cart.state))
	}, [cart.state]);

	const handleOnClick = useCallback(() => {
		handleClose()
		dispatchCleanAllList(getCartQuantity(cart.state), getCartItemNames(cart.state), getCartQuantities(cart.state), getCartPrices(cart.state))
		clean()
	}, [cart.state, clean, handleClose])

	return (
		<>
			<Dialog open={open} onClose={handleClose} onClick={handleOnClick} />
			<Button className={classes.button} onClick={handleOpen}><IconHeaderDelete /></Button>
		</>
	)
}
