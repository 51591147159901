import { useEffect } from 'react'
import Snackbar from '@material-ui/core/Snackbar';

export function SimpleToast({ open = false, message, icon, onClose }) {
	useEffect(() => {
		if (open === true) {
			const timeout = setTimeout(() => {
				onClose();
			  }, 2000);

			 return () => clearTimeout(timeout);
		}
	}, [onClose, open])

	return <Snackbar
	anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
	open={open}
	onClose={onClose}
	onClick={onClose}
	message={(
		<>
			{icon}
			<span className="__message">{message}</span>
		</>
	)}
  />
}
