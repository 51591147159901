import { asArray } from "../objectUtils"

function filterItemsByFilterList(items, filters) {
	if (filters?.length) {
		return items.filter(item => filters.find(f => !!item[f]))
	}
}

export function filterSectionsByFilterList(sections, filters) {
	if (filters.length) {
		return asArray(sections).map(m => ({ ...m, items: filterItemsByFilterList(m.items, filters) }))
	}

	return sections
}
