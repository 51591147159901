import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
	component: {
		margin: "auto",
		flex: 1,
		flexDirection: "column",
		position: "relative",
		display: ({ centered }) => (centered ? "flex" : "block"),
		alignItems: ({ centered }) => centered && "center",
		justifyContent: ({ centered }) => centered && "center",

		backgroundColor: ({ light }) => (light ? "var(--color-white)" : "var(--color-black)"),
		color: ({ light }) => (!light ? "var(--color-white)" : "var(--color-black)"),
		maxWidth: "100%",
		overflowY: "scroll",
		width: '100vw',
		height: ({ height }) => (height || '100%'),
		'@media screen and (min-width: 640px)': {
			width: 414,
			maxHeight: 719,

		},
	},
	ultraComponent: {
		position: 'fixed',
		zIndex: '999'
	},
	goToCart: {
		textDecoration: 'none',
		cursor: 'pointer',
		backgroundColor: 'black',
		borderRadius: '0.75rem',
		boxShadow: '0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1)',
		fontSize: '.9625rem',
		fontWeight: '400',
		lineHeight: '1.6',
		color: 'white',
		width: 'fit-content',
		padding: '0.5rem 1.05rem',
		position: 'fixed',
		bottom: '1rem',
		left: 0,
		right: 0,
		margin: 'auto',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		'& svg': {
			marginRight: '1rem'
		}
	}
});
