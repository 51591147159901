import { useCallback } from "react";

import { setItemReview } from '../../services/restaurant-service'
import { useRestaurantContext } from "../../store/restaurant";

export function useReviewAction() {
	const restaurant = useRestaurantContext()

	const submit = useCallback(async (itemId, value) => {
		try {
			return await setItemReview(restaurant.state.id, itemId, value)
		} catch (err) {
			console.error('err ->', err)
		}
	}, [restaurant.state.id])

	return { submit }
}
