import { useCallback, useState, useMemo } from "react"
import classNames from "classnames"
import { Button } from "@material-ui/core"

import { SimpleDialog } from '../dialog'
import { SimpleToast } from '../toast'

import { useReviewAction } from './review-action-hook'
import { useStyles } from './review-now-action.styles'
import { dispatchApplyRating, dispatchCloseRating, dispatchStartRating } from "../../services/firebase"
import { useMenuItemContext } from "../../store/menu-item"

function StarIcon({ value, stars, onChange }) {
	const classes = useStyles()

	const selected = useMemo(() => value <= stars, [stars, value])

	const handleChangeStars = useCallback(() => {
		if (value !== stars) {
			return onChange(value)
		}

	}, [onChange, stars, value])

	return (
		<svg onClick={handleChangeStars} className={classNames(classes.icon, { [classes.iconSelected]: selected })} width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M6.9719 32.7862C7.60268 33.2785 8.40269 33.1093 9.35654 32.417L17.4951 26.4323L25.649 32.417C26.6029 33.1093 27.3875 33.2785 28.0337 32.7862C28.6644 32.3093 28.8029 31.5247 28.4183 30.4016L25.2029 20.8322L33.4183 14.9245C34.3722 14.2475 34.7568 13.5398 34.5107 12.7706C34.2645 12.0321 33.5414 11.6629 32.3568 11.6783L22.2798 11.7398L19.2182 2.12434C18.849 0.985863 18.2951 0.416626 17.4951 0.416626C16.7105 0.416626 16.1566 0.985863 15.7874 2.12434L12.7258 11.7398L2.64878 11.6783C1.46415 11.6629 0.741063 12.0321 0.494907 12.7706C0.233365 13.5398 0.63337 14.2475 1.58723 14.9245L9.8027 20.8322L6.58728 30.4016C6.20266 31.5247 6.34112 32.3093 6.9719 32.7862Z" />
		</svg>
	)
}

function Dialog({ open, onClose, onClick }) {
	const classes = useStyles()
	const [stars, changeStars] = useState(0)

	const handleOnClose = useCallback(() => {
		dispatchCloseRating(stars)
		onClose()
	}, [onClose, stars])

	return (
		<SimpleDialog open={open} onClose={handleOnClose} title="Avalie sua experiência">
			<div className={classes.component}>
				<div className={classes.content}>
					<p className={classes.label}>Seleciona as estrelas e avalie!</p>
					<div className={classes.stars}>
						<StarIcon value={1} stars={stars} onChange={changeStars} />
						<StarIcon value={2} stars={stars} onChange={changeStars} />
						<StarIcon value={3} stars={stars} onChange={changeStars} />
						<StarIcon value={4} stars={stars} onChange={changeStars} />
						<StarIcon value={5} stars={stars} onChange={changeStars} />
					</div>
				</div>
				{stars > 0 && <Button variant="contained" color="primary" fullWidth onClick={() => onClick(stars)}>Avaliar</Button>}
			</div>
		</SimpleDialog>
	)
}

function Toast({ open, onClose }) {

	return <SimpleToast icon={(
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M3.83731 18.948C4.20654 19.2362 4.67484 19.1372 5.23319 18.7319L9.99722 15.2287L14.7703 18.7319C15.3286 19.1372 15.7879 19.2362 16.1661 18.948C16.5354 18.6689 16.6164 18.2096 16.3913 17.5522L14.5091 11.9506L19.3181 8.4924C19.8765 8.09615 20.1016 7.68188 19.9576 7.2316C19.8135 6.79932 19.3902 6.58319 18.6968 6.59219L12.798 6.62821L11.0059 0.999636C10.7897 0.333212 10.4655 0 9.99722 0C9.53793 0 9.21372 0.333212 8.99759 0.999636L7.20545 6.62821L1.3067 6.59219C0.613255 6.58319 0.189986 6.79932 0.0458942 7.2316C-0.107203 7.68188 0.126946 8.09615 0.685301 8.4924L5.49436 11.9506L3.61216 17.5522C3.38702 18.2096 3.46807 18.6689 3.83731 18.948Z" fill="white"/>
		</svg>
	)} message="Obrigada por avaliar!" open={open} onClose={onClose} />
}

export function ReviewNowAction({ className }) {
	const [open, setOpen] = useState(false);
	const [toast, setToast] = useState(false);
	const menuItem = useMenuItemContext()
	const { submit } = useReviewAction()

	const handleToastOff = useCallback(() => {
		setToast(false)
	}, [])

	const handleToastOn = useCallback(() => {
		setToast(true)
	}, [])

	const handleOpen = useCallback(() => {
        dispatchStartRating(menuItem.state.current.name, menuItem.state.current.averageReview)
		setOpen(true);
	}, [menuItem]);

	const handleClose = useCallback(() => {
		setOpen(false);
	}, []);

	const handleOnClick = useCallback(async (value) => {
		await submit(menuItem.state.current.id, value)
		dispatchApplyRating(value)
		handleToastOn()
		handleClose()

	}, [handleClose, handleToastOn, menuItem, submit])

	return (
		<>
			<Toast open={toast} onClose={handleToastOff} />
			<Dialog open={open} onClose={handleClose} onClick={handleOnClick} />
			<Button size="small" onClick={handleOpen} className={classNames("__review-action", className)}>Avalie agora</Button>
		</>
	)
}
