import { Button } from '@material-ui/core'
import { makeStyles } from "@material-ui/styles"

import { useCartActions } from '../../../store/cart'
import { IconAddButtonSmall, IconRemoveButtonSmall } from '../../../components/icons'
import { useCallback } from 'react'

const useStyles = makeStyles({
	component: {
		border: '2px solid #ECEBEF',
		borderRadius: 12,
		padding: '0 4px',
		width: 'fit-content',
		marginTop: '0.5rem'
	},
	button: {
		minWidth: 'fit-content !important',
		'& path': {
			fill: 'var(--color-primary)'
		},
		'&:disabled path': {
			fill: '#ECEBEF'
		}
	},
	value: {
		width: 20,
		display: 'inline-block',
		textAlign: 'center'
	}
})

export function ChangeItemQuantity({ itemId, quantity }) {
	const classes = useStyles()
	const { increment, decrement } = useCartActions()

	const handleOnDecrement = useCallback(() => {
		if (quantity > 1) {
			decrement(itemId)
		}
	}, [decrement, itemId, quantity])

	const handleOnIncrement = useCallback(() => {
		increment(itemId)
	}, [increment, itemId])

	return (
		<div className={classes.component}>
			<Button className={classes.button} onClick={handleOnDecrement} disabled={quantity <= 1}><IconRemoveButtonSmall /></Button>
			<span className={classes.value}>{quantity}</span>
			<Button className={classes.button} onClick={handleOnIncrement}><IconAddButtonSmall /></Button>
		</div>
	)
}
