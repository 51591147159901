import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
	component: {
		width: '100%',
		minHeight: 180,
		justifyContent: 'space-between',
		display: 'flex',
		flexDirection: 'column'
	},
	content: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		marginBottom: '2.75rem'
	},
	label: {
		marginTop: '0.75rem',
		marginBottom: '1rem',
		fontSize: '1.0625rem',
		fontWeight: '400',
		lineHeight: '1.6',
	},
	icon: {
		'& + &': {
			marginLeft: '0.875rem'
		},
		'& path': {
			fill: '#cccccc',
		}
	},
	iconSelected: {
		'& path': {
			fill: '#FFBB00'
		}
	}
})
