import { Container } from '../../components/container'
import { Loader } from '../../components/loader'

export function LoadingScreen() {
	return (
		<Container centered>
			<Loader />
		</Container>
	)
}
