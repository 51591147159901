import React from 'react'

import { Component, Dot, Dots } from './loader.styled'

export function Loader({ light = false, show = true }) {
	return (
		<Component light={light} show={show}>
			<div>
				<Dot light={light}></Dot>
				<Dots light={light}>
					<span></span><span></span><span></span>
				</Dots>
			</div>
		</Component>
	)
}
