import React from 'react'

import { useMenuContext } from '../../../../store/menu'
import { useMenu } from '../../../../hooks/menu-hook'
import { useAppActions } from '../../../../hooks/app-actions-hook'
import { Container } from '../../../../components/container'
import { Header } from '../../../../components/header'
import { NavigationMenuSectionTab } from '../../../../components/restaurant/navigation-menu-section-tab'
// import { NavigationMenuSectionFilter } from '../../../../components/restaurant/navigation-menu-section-filter'
import { RestaurantMenuPremiumFeaturedGroup } from '../../../../components/restaurant/menu/premium/featured-group'
import { RestaurantMenuPremiumSection } from '../../../../components/restaurant/menu/premium/section'
import { SectionAbout } from './components/section-about'

import { useStyles } from './restaurant-menu-premium.styles'
import { asSingle, isObject } from '../../../../utilities/objectUtils'
import { isValidString } from '../../../../utilities/stringUtils'
import { useEffect } from 'react'
import { useRestaurantContext } from '../../../../store/restaurant'


export function RestaurantMenuPremium() {
	const menu = useMenuContext()
	const restaurant = useRestaurantContext()
	const { backToMenuNavigation } = useAppActions()
	const { section, sections, activedFilters, filteredMenuSections, selectSection } = useMenu()
	const classes = useStyles()

	useEffect(() => {
		document.getElementById('app-container').scrollTo(0, 0);
	}, [menu.state, section, activedFilters])

	const renderContent = () => {
		if (section === sections.length + 1) {
			return <SectionAbout value={menu.state.freeDescription} />
		}

		return (
			<>
				<RestaurantMenuPremiumFeaturedGroup section={menu.state.featuredItems} />
				<div className={classes.sections}>
					{isObject(section)
						? <RestaurantMenuPremiumSection section={asSingle(filteredMenuSections)} />
						: filteredMenuSections.map(_section => <RestaurantMenuPremiumSection section={_section} key={_section.id} />)}
				</div>
			</>
		)
	}

	return (
		<Container withCartFeedBack>
			<div className={classes.stickyHeader}>
				<Header onBack={backToMenuNavigation} renderOnRight={isValidString(restaurant.state.logoPictureUrl) ? <img height="36" src={restaurant.state.logoPictureUrl} alt={restaurant.state.name} /> : null} />
				<div className={classes.filtersRow} >
					{/* <NavigationMenuSectionFilter onChange={filter} activedFilters={activedFilters} /> */}
					<NavigationMenuSectionTab sections={sections} selected={section} onChange={selectSection} />
				</div>
			</div>
			<div className={classes.content}>
				{renderContent()}
			</div>
		</Container>
	)
}
