import { useCallback } from 'react'
import { toCurrencyString } from '../../../../../../../formatters'
import { dispatchMatchItemSelect } from '../../../../../../../services/firebase'
import { useMenuItemActions, useMenuItemContext } from '../../../../../../../store/menu-item'

import { useStyles } from "./restaurant-menu-premium-featured-card.styles"

export function RestaurantMenuPremiumFeaturedCard({ item, isSuggestion }) {
	const classes = useStyles()
	const menuItem = useMenuItemContext()
	const { setMenuItem } = useMenuItemActions()

	const handleOnClick = useCallback(() => {
		if (isSuggestion) {
			dispatchMatchItemSelect(item.name, item.price, menuItem.state.current.name, menuItem.state.current.price, menuItem.state.current.averageReview)
		}
		setMenuItem(item)
	}, [isSuggestion, item, menuItem.state, setMenuItem])

	return (
		<div className={classes.component} onClick={handleOnClick}>
			<div className={classes.cover} style={{ backgroundImage: `url(${item.pictureUrl})` }}></div>
			<div className={classes.content}>
				{isSuggestion && <p className={classes.suggestion}>Vai bem com</p>}
				<h4 className={classes.title}>{item.name}</h4>
				<span className={classes.price}>{toCurrencyString(item.price)}</span>
			</div>
		</div>
	)
}
