
import classNames from 'classnames'

import { ReviewFlag } from '../../../../review-flag'
import { toCurrencyString } from '../../../../../formatters'

import { useStyles } from "./restaurant-menu-item.styles"
import { useMemo } from 'react'
import { useCallback } from 'react'
import { useMenuItemActions } from '../../../../../store/menu-item'

export function RestaurantPremiumMenuItem({ item }) {
	const { id, name, pictureUrl, description, price, averageReview, sections, isHighlighted } = item
	const { setMenuItem } = useMenuItemActions()

	const sectionsLabel = useMemo(() => {
		let _string = ''
		sections.forEach((x, i) => {
			if (i === 0) {
				_string += x.name
			} else {
				_string += `, ${x.name}`
			}
		});
		return _string
	}, [sections])

	const classes = useStyles({ image: pictureUrl })

	const handleOnClick = useCallback(() => {
		setMenuItem(item)
	}, [item, setMenuItem])

	return (
		<article onClick={handleOnClick} className={classNames(classes.component, classes.full, {
			[classes.featured]: isHighlighted
		})} id={`restaurant-premium-menu-item-${id}`}>
			<div className={classNames(classes.fullCover, {
				[classes.fullCoverWithImage]: !!pictureUrl,
				[classes.featuredFullCover]: !!pictureUrl && isHighlighted
			})}>
				<ReviewFlag value={averageReview} />
			</div>
			<div className={classNames(classes.fullContent, {
				[classes.fullContentWithoutImage]: !pictureUrl
			})}>
				{!pictureUrl && sectionsLabel && <p className={classes.fullSectionLabel}>{sectionsLabel}</p>}
				<h4 className={classNames(classes.fullTitle, {
					[classes.fullTitleLarge]: !pictureUrl
				})}>{name}</h4>
				{!pictureUrl && description && <p className={classes.fullShortDescription}>{description}</p>}
				<p className={classes.fullPrice}>{toCurrencyString(price)}</p>
			</div>
		</article>
	)
}
