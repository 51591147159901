import { Tabs, Tab } from '@material-ui/core';
import { useRestaurantContext } from '../../../store/restaurant';
import { isObject } from '../../../utilities/objectUtils';

import { useStyles } from './navigation-menu-section-tab.styles'

function a11yProps(index) {
	return {
	  id: `section-tab-${index}`,
	  'aria-controls': `section-tabpanel-${index}`,
	  'data-section': index
	};
  }

export function NavigationMenuSectionTab({ sections, selected, onChange }) {
	const restaurant = useRestaurantContext()
	const classes = useStyles()

	return (
			<Tabs
				className={classes.component}
				value={isObject(selected) ? selected.index + 1 : selected}
				onChange={onChange}
			>
				<Tab label="Todos" {...a11yProps(0)} />
				{sections.map(s => <Tab key={s.id} label={s.name} {...a11yProps(s.id)} />)}
				<Tab label={`Sobre o ${restaurant.state.name}`} {...a11yProps(900)} />
			</Tabs>
	)
}
