import { useParams } from 'react-router'

import { LoadingScreen } from '../loading'

import { useRestaurantScreen } from './hooks/restaurant-screen-hook'
import { RestaurantScreenContainer } from './restaurant-screen-container'

export function RestaurantScreen() {
	const { restaurant } = useParams()
	const { loading } = useRestaurantScreen(restaurant)

	if (loading) {
			return <LoadingScreen />
	}

	return <RestaurantScreenContainer />
}
