import { Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { useMenuItemContext } from "../../../store/menu-item"

const useStyles = makeStyles({
	component: {
		marginLeft: '-0.25rem',
		marginRight: '-0.25rem',
		marginTop: '1rem',
		marginBottom: '1rem'
	},
	item: {
		backgroundColor: 'transparent'
	}
})

export function MenuItemFilterLabels() {
	const classes = useStyles()
	const { state } = useMenuItemContext()
	const { isVegetarian, isVegan, glutenFree } = state.current

	const _renderComponent = (label) => (
		<Button
			className='__disabled'
			variant="outlined"
			color="inherit"
			size="small"
			disabled
		>
			{label}
		</Button>
	)

	if (!isVegan && !isVegetarian && !glutenFree) {
		return null
	}

	return (
		<div className={classes.component}>
			{isVegan && _renderComponent('vegano')}
			{isVegetarian && _renderComponent('vegetariano')}
			{glutenFree && _renderComponent('sem glúten')}
		</div>
	)
}
