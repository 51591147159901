import { useEffect, useState } from "react"

export function use100vh() {
	const [height, setHeight] = useState(measureHeight)

	const wasRenderedOnClientAtLeastOnce = useWasRenderedOnClientAtLeastOnce()

	useEffect(() => {
	  if (!wasRenderedOnClientAtLeastOnce) return

	  function setMeasuredHeight() {
		const measuredHeight = measureHeight()
		setHeight(measuredHeight)
	  }

	  window.addEventListener('resize', setMeasuredHeight)
	  return () => window.removeEventListener('resize', setMeasuredHeight)
	}, [wasRenderedOnClientAtLeastOnce])
	return wasRenderedOnClientAtLeastOnce ? height : null
  }

  export function measureHeight() {
	if (!isClient()) return null
	return document.documentElement?.clientHeight || window.innerHeight
  }

  // Once we ended up on client, the first render must look the same as on
  // the server so hydration happens without problems. _Then_ we immediately
  // schedule a subsequent update and return the height measured on the client.
  // It's not needed for CSR-only apps, but is critical for SSR.
  function useWasRenderedOnClientAtLeastOnce() {
	const [
	  wasRenderedOnClientAtLeastOnce,
	  setWasRenderedOnClientAtLeastOnce
	] = useState(false)

	useEffect(() => {
	  if (isClient()) {
		setWasRenderedOnClientAtLeastOnce(true)
	  }
	}, [])
	return wasRenderedOnClientAtLeastOnce
  }

  function isClient() {
	return typeof window !== 'undefined' && typeof document !== 'undefined'
  }
