import '@brainhubeu/react-carousel/lib/style.css';
import { useState } from 'react';
import Carousel from '@brainhubeu/react-carousel';

import { CarouselMenuItem } from './components/carousel-menu-item';

import { useStyles } from "./carousel-menus.styles"
import { AboutChefsClubScreen } from '../../screens/about-chefsclub';
import { useCallback } from 'react';

const chefsClubItem = {
	id: 0,
	name: 'Sobre o ChefsClub',
	coverPictureUrl: '',
	aboutChefs: true
}

export function CarouselMenus({ items, select }) {
	const classes = useStyles()
	const [showAboutChefsClub, setShowAboutChefsClub] = useState(false)

	const handleOnBackFromAbout = useCallback(() => {
		setShowAboutChefsClub(false)
	}, [])

	const handleOnClick = useCallback((id) => {
		if (id === 0) {
			return setShowAboutChefsClub(true)
		}

		return select(id)
	}, [select])

	if (showAboutChefsClub) {
		return <AboutChefsClubScreen onBack={handleOnBackFromAbout} />
	}

	if (typeof items !== 'object' || !items.length) {
		return <div />
	}

	return (
		<div className={classes.row}>
			<div className={classes.component}>
				<Carousel itemWidth={277+24}>
					{[ ...items, chefsClubItem].map(({
						id,
						name,
						coverPictureUrl,
						aboutChefs
					}) => <CarouselMenuItem
						key={`menu-item-${id}`}
						label={name}
						image={coverPictureUrl}
						onClick={() => handleOnClick(id)}
						aboutChefs={aboutChefs}
					/>)}
				</Carousel>
			</div>
		</div>
	)
}
