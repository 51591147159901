import { useReducer } from "react";
import { dispatchDecreaseQuantity, dispatchAddQuantity } from "../../services/firebase";

import { initialState, cartStore } from "./cart-store";

const { Provider } = cartStore;

export const CartProvider = ({ children }) => {
	const [state, dispatch] = useReducer((state, { type, value }) => {
		switch(type) {
			case 'CLEAN':
				return []
			case 'REMOVE_ITEM':
				return [ ...state.filter(({ item }) => item.id !== value) ]
			case 'ADD_ITEM':
				if (state.find(s => s.item.id === value.item.id)) {
					return [ ...state.map(({ item, quantity }) => {
						if (item.id === value.item.id) {
							quantity = quantity + value.quantity
						}
						return { item, quantity }
					}) ]
				}
				return [ ...state, value ]
			case 'INCREMENT':
				return [ ...state.map(({ item, quantity }) => {
					if (item.id === value) {
						quantity = quantity + 1
						dispatchAddQuantity(quantity)
					}
					return { item, quantity }
				}) ]
			case 'DECREMENT':
				return [ ...state.map(({ item, quantity }) => {
					if (item.id === value) {
						quantity = quantity - 1
						dispatchDecreaseQuantity(quantity)
					}
					return { item, quantity }
				}) ]
			default:
				throw new Error('Store action should be a type')
		}
	}, initialState)

	return <Provider value={{ state, dispatch }}>{children}</Provider>;
}

