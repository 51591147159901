import { createGlobalStyle } from "styled-components";

export const AppStyles = createGlobalStyle`
	:root {
		--color-primary: ${({ color }) => color} !important
	}

	.__simple-dialog .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
		bottom: 0;
		position: fixed;
		margin: 0;
		left: 0;
		right: 0;
		border-radius: 12px 12px 0px 0px;
		box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.3);
		background: var(--color-white);
		margin: auto;
		max-width: 425px;
	}

	.__not-simple-dialog .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
		border-radius: 0.875rem;
		max-width: 280px;

		.MuiDialogActions-root.MuiDialogActions-spacing {
			padding-left: 0;
			padding-bottom: 0;
			padding-right: 0;
		}
	}

	.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButtonBase-root {
		background-color: var(--color-primary);
		font-size: 1.125rem;
		font-weight: 500;
		text-transform: inherit;
		letter-spacing: -0.41px;
		line-height: 1.45;
		border-radius: 0.375rem;
		min-height: 2.75rem;
		border-width: 0;
	}

	.MuiButton-root.MuiButton-text.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButtonBase-root {
		&.__review-action {
			text-transform: inherit;
			font-size: 0.8125rem;
			font-weight: 600;
			letter-spacing: -0.08px;
			border-radius: 0.375rem;
			line-height: 1.4;
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			backdrop-filter: blur(8px);
			background-color: rgba(0,0,0, 0.4);
			color: white;

			& + span {
				margin-left: 0.825rem;
			}
		}

		&.__cart-action {
			background-color: #ff0000;
			text-transform: inherit;
			font-size: 0.8125rem;
			font-weight: 600;
			letter-spacing: -0.08px;
			border-radius: 0.375rem;
			line-height: 1.4;
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			backdrop-filter: blur(8px);
			color: white;
			height: 2rem;

			& + span {
				margin-left: 0.825rem;
			}
		}
	}

	.MuiButton-root.MuiButton-outlined.MuiButton-sizeSmall.MuiButton-outlinedSizeSmall.MuiButtonBase-root {
		border-radius: 0.375rem;
		font-size: 0.925rem;
		letter-spacing: -0.24px;
		text-transform: inherit;
		min-height: 2rem;
		margin: 0.25rem;

		&.MuiButton-outlinedInherit.MuiButton-colorInherit {
			background-color: #F5F5F5;
			border: 1px solid #CCCCCC;
			color: var(--color-secondary);
		}

		&.MuiButton-outlinedPrimary {
			background-color: var(--color-primary);
			border-color: var(--color-primary);
			color: white;
		}

		&.Mui-disabled.__disabled {
			background: transparent;
			border-color: var(--color-primary);
			color: var(--color-primary);
			font-size: 0.6875rem;
			min-height: 1.5rem;
		}

	}
	.MuiTypography-root.MuiTypography-inherit.MuiLink-root.MuiLink-underlineHover {
		font-size: 1.125rem;
		font-weight: 500;
		text-transform: inherit;
		letter-spacing: -0.41px;
		line-height: 1.45;
		text-align: center;
		color: black;
		text-decoration: underline;
		padding: 1rem 1rem .525rem;
		cursor: pointer;
	}

	.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation6.MuiSnackbarContent-root {
		background-color: var(--color-primary);
		border-radius: 0.75rem;
		box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1);
		font-size: 1.0625rem;
		font-weight: 400;
		line-height: 1.6;

		& .MuiSnackbarContent-message {
			padding: 0.375rem 0;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		& svg + span.__message {
			margin-left: 1rem;
		}
	}
`
