import React from "react";
import ReactDOM from "react-dom";

import { App } from "./components/app";
import GlobalStyles from "./styles/global";

import { MenuProvider } from './store/menu'
import { RestaurantProvider } from "./store/restaurant";
import { MenuItemProvider } from './store/menu-item';
import { CartProvider } from './store/cart';
import { AppProvider } from "./store/app";

ReactDOM.render(
	<AppProvider>
		<RestaurantProvider>
			<MenuProvider>
				<MenuItemProvider>
					<CartProvider>
						<GlobalStyles />
						<App />
					</CartProvider>
				</MenuItemProvider>
			</MenuProvider>
		</RestaurantProvider>
	</AppProvider>,
	document.getElementById("app-entry")
);
