import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
	component: {},
	title: {
		margin: "0.5rem 1rem",
		fontSize: "1.25rem",
		lineHeight: "1.25",
		fontWeight: "700",
		color: "var(--color-primary)"
	}
});
