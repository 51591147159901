import { useState } from 'react'
import { useMenuActions, useMenuContext } from '../../store/menu'
import { useRestaurantContext } from '../../store/restaurant'
import { useMenuItemContext } from '../../store/menu-item'
import { isObject } from '../../utilities/objectUtils'

import { SimpleToast } from '../../components/toast'
import { IconToastCheck } from '../../components/icons'
import { CarouselMenus } from '../../components/carousel-menus'
import { Container } from '../../components/container'
import { MenuItemScreen } from '../menu-item/menu-item-screen-component'
import { RestaurantMenuScreen } from '../restaurant-menu'
import { useAppContext } from '../../store/app'
import { CartScreen } from '../cart/cart-screen-component'

function Toast({ open, onClose }) {
	return <SimpleToast icon={<IconToastCheck />} message="Prato adicionado na sua lista!" open={open} onClose={onClose} />
}

export function RestaurantScreenContainer() {
	const [addToast, setAddToast] = useState(false);
	const restaurant = useRestaurantContext()
	const menu = useMenuContext()
	const menuItem = useMenuItemContext()
	const app = useAppContext()
	const { setMenu } = useMenuActions()

	if (restaurant.state === null) {
		throw new Error('Restaurant should be not empty')
	}

	if (isObject(menu.state)) {
		return (
			<>
				<Toast open={addToast} onClose={() => setAddToast(false)} />
				{app.state.onCart && <CartScreen />}
				{isObject(menuItem.state.current) && !app.state.onCart && <MenuItemScreen onAdded={() => setAddToast(true)} />}
				<RestaurantMenuScreen />
			</>
		)
	}

	return (
		<Container centered>
			<CarouselMenus items={restaurant.state.menus} select={setMenu} />
		</Container>
	)
}
