import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";
import paths from "../../paths";

import { AboutChefsClubScreen } from '../../screens/about-chefsclub'
import { RestaurantScreen } from "../../screens/restaurant";
import { CartScreen } from '../../screens/cart'
import { RedirectScreen } from '../../screens/redirect'
import { useRestaurantContext } from "../../store/restaurant";

import { AppStyles } from "./app-styles";
import { requestMessagingPermission } from "../../services/firebase/firebase-messaging-service";

function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null
}

export const App = () => {
	const restaurant = useRestaurantContext()

	useEffect(() => {
		requestMessagingPermission().then(_token => window.messagingToken = _token).catch(err => console.error(err))
	}, [])

	return (
		<main>
			<AppStyles color={restaurant.state?.accentColor || '#000000'} />
			<BrowserRouter>
				<ScrollToTop />
				<Switch>
					<Route path={paths.aboutChefsClub}>
						<AboutChefsClubScreen />
					</Route>
					<Route path={paths.notFound}>
						<h1>Página não encontrada!</h1>
					</Route>
					<Route path={paths.appError}>
						<h1>AppError!</h1>
					</Route>
					<Route path={paths.cart}>
						<CartScreen />
					</Route>
					<Route path={paths.redirect}>
						<RedirectScreen />
					</Route>
					<Route path="/:restaurant">
						<RestaurantScreen />
					</Route>
				</Switch>
			</BrowserRouter>
		</main>
	);
};
