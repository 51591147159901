import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'

const useStyles = makeStyles({
	component: {
		padding: '1rem',
		color: 'var(--color-secondary)',
		lineHeight: '1.5',
		'& h3, & h2, & h4': {
			fontWeight: 'bold',
			fontSize: '1.25rem',
			marginBottom: '1.5rem'
		},
		'& p + p': {
			marginTop: '1.5rem'
		}
	}
})

export function SectionAbout({ className, value }) {
	const classes = useStyles()
	return <div className={classNames(className, classes.component)} dangerouslySetInnerHTML={{ __html: value }}></div>
}
