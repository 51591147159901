import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
	component: {},
	stickyHeader: {
		position: 'fixed',
		zIndex: 200,
		width: 414,
		maxWidth: '100%',
		background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%)',
		transition: '.2s all ease-in-out',
		'@media screen and (max-width: 640px)': {
			top: 0
		}
	},
	scrolled: {
		background: 'linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%)',
		boxShadow: '0px 0.5px 0px rgba(0, 0, 0, 0.3)'
	},
	headerWithoutImage: {
		boxShadow: '0px 0.5px 0px rgb(0 0 0 / 10%)',
		background: 'white'
	},
	cover: {
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		height: 364,
		backgroundColor: 'var(--color-secondary-light)',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-end',
		flexDirection: 'row',
		padding: '2rem 1rem'
	},
	withoutImage: {
		background: 'white !important',
		height: 146,
		marginBottom: '-1.5rem',
	},
	content: {
		backgroundColor: 'white',
		borderTopLeftRadius: '0.875rem',
		borderTopRightRadius: '0.875rem',
		padding: '1.5rem 1rem 0',
		marginTop: '-0.875rem'
	},
	text: {
		fontSize: '0.8125rem',
		color: 'var(--color-secondary)',
		fontWeight: '500',
		letterSpacing: '-0.08px',
		lineHeight: '1.45'
	},
	sectionsLabel: {
	},
	title: {
		fontSize: '1.75rem',
		fontWeight: '700',
		color: 'var(--color-primary)',
		lineHeight: '1.3',
		marginTop: '0.25rem',
		marginBottom: '0.25rem'
	},
	price: {
		fontSize: '1.125rem',
		lineHeight: '1.5',
		letterSpacing: '-0.41px',
		color: 'var(--color-secondary)',
		fontWeight: '700'
	},
	description: {
		marginTop: '0.5rem',
		marginBottom: '0.5rem'
	},
})
