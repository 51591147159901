import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
	component: {
		backgroundColor: "var(--color-white)",
		overflow: "hidden",
		margin: "0.75rem 1rem 1.5rem"
	},
	full: {
		borderRadius: "0.75rem",
		boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.08)"
	},
	fullContent: {
		padding: "1rem"
	},
	fullContentWithoutImage: {
		paddingTop: "0.5rem"
	},
	fullCover: {
		padding: "1rem",
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "flex-end"
	},
	fullCoverWithImage: {
		height: 220,
		backgroundImage: ({ image }) => `url(${image})`,
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		backgroundPosition: "center"
	},
	featuredFullCover: {
		height: 375
	},
	fullSectionLabel: {
		fontSize: "0.7rem",
		lineHeight: "1.15",
		color: "var(--color-secondary)",
		fontWeight: "400",
		marginBottom: "0.225rem"
	},
	fullTitle: {
		color: "var(--color-primary)",
		fontWeight: "700",
		letterSpacing: "-0.41px",
		fontSize: "1.125rem",
		lineHeight: "1.075",
		marginBottom: "0.275rem"
	},
	fullTitleLarge: {
		fontSize: "1.75rem"
	},
	fullShortDescription: {
		fontSize: "0.775rem",
		marginBottom: "1.5rem",
		color: "var(--color-black)",
		lineHeight: "1.5",
		fontWeight: "400"
	},
	fullPrice: {
		color: "var(--color-secondary)",
		fontWeight: "600",
		lineHeight: "1.35"
	}
});
