import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
	component: {
		margin: "1.5rem 0 3rem",
		overflow: "hidden"
	},
	single: {
		paddingLeft: "1rem",
		paddingRight: "1rem"
	},
	title: {
		color: "var(--color-primary)",
		fontSize: "1.75rem",
		fontWeight: "700",
		margin: "0 1rem 1rem"
	},
	suggestions: {
		marginTop: '2rem',
		paddingBottom: '1rem'
	}
});
