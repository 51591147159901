import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
	sticky: {
		boxShadow: '0px 0.5px 0px rgba(0, 0, 0, 0.3)',
		backgroundColor: 'white',
		'@media screen and (max-width: 640px)': {
			top: 0,
		}
	},
	component: {
		backgroundColor: 'var(--color-secondary-light)',
		minHeight: 'calc(812px - 74px)',
		overflowX: 'hidden'
	},
	header: {
		padding: '1.5rem 1rem 1rem',
		'& p': {
			color: 'var(--color-secondary)',
			fontSize: 13,
			lineHeight: '1.36',
			letterSpacing: '-0.08px'
		}
	},
	title: {
		fontWeight: '700',
		color: 'var(--color-primary)',
		fontSize: '1.75rem',
		marginBottom: '0.5rem',
		letterSpacing: '-0.41px'
	}
})
