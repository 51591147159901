import { useCallback } from "react"

import { useAppContext } from "./app-store-hook"

export function useAppActions() {
	const { dispatch } = useAppContext()

	const clean = useCallback(() => {
		return dispatch({ type: 'CLEAN' })
	}, [dispatch])

	const setSnackBar = useCallback((value) => {
		return dispatch({ type: 'SNACKBAR_SET' })
	}, [dispatch])

	const goToCart = useCallback(() => {
		return dispatch({ type: 'GO_TO_CART' })
	}, [dispatch])

	const getOutOfTheCart = useCallback(() => {
		return dispatch({ type: 'GET_OUT_OF_THE_CART' })
	}, [dispatch])

	return {
		clean,
		setSnackBar,
		goToCart,
		getOutOfTheCart
	}
}
