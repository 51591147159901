import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
	component: {
		"backgroundColor": "rgba(255,255,255,0.7)",
		"backdropFilter": "blur(8px)",
		"padding": "0.375rem 0.65rem",
		"borderRadius": "0.375rem",
		"fontWeight": "600",
		"fontSize": "0.775rem",
		"letterSpacing": "-0.08px",
		"color": "var(--black-light)",
		"display": "flex",
		"alignItems": "center",
		"justifyContent": "center",
		"width": "fit-content",

		"& svg": {
			marginRight: "0.25rem"
		}
	}
});
