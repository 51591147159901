import { useCallback } from "react"
import { useCartActions } from "../../../store/cart"
import { useMenuItemActions, useMenuItemContext } from "../../../store/menu-item"

export function useAddToCart() {
	const CartActions = useCartActions()
	const MenuItemActions = useMenuItemActions()
	const menuItem = useMenuItemContext()

	const submit = useCallback(async (value) => {
		await CartActions.add({
			item: menuItem.state.current,
			quantity: value
		})
		await MenuItemActions.clean()
	}, [CartActions, MenuItemActions, menuItem.state])

	return { submit }
}
