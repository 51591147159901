import { useReducer } from "react";

import { initialState, menuStore } from "./menu-store";

const { Provider } = menuStore;

export const MenuProvider = ({ children }) => {
	const [state, dispatch] = useReducer((state, { type, value }) => {
		switch(type) {
			case 'CREATE':
				return value
			case 'SELECT_SECTION':
				return state
			default:
				throw new Error('Store action should be a type')
		}
	}, initialState)

	return <Provider value={{ state, dispatch }}>{children}</Provider>;
}

