import { useEffect } from "react"
import { useHistory } from "react-router";
import { useQuery } from '../../hooks/route-query-hook'

import { findRestaurantByHash } from "../../services/redirect-service"

import { LoadingScreen } from '../loading'

export function RedirectScreen() {
  const query = useQuery();
  const history = useHistory();

  useEffect(() => {
	function onFindRestaurantByHashResult(data) {
	if (data != null) {
		history.push(data.restaurant_id)
	}
	}

    var hashCode = query.get("hash")
    findRestaurantByHash(hashCode, onFindRestaurantByHashResult)
  }, [history, query])


  return (
    <LoadingScreen />
  )
}
