import { useReducer } from "react";

import { initialState, menuItemStore } from "./menu-item-store";

const { Provider } = menuItemStore;

export const MenuItemProvider = ({ children }) => {
	const [state, dispatch] = useReducer((state, { type, value }) => {
		switch(type) {
			case 'CLEAN':
				return initialState
			case 'BACK':
				return {
					previous: null,
					current: state.previous
				}
			case 'CREATE':
				return {
					previous: state.current,
					current: value
				}
			default:
				throw new Error('Store action should be a type')
		}
	}, initialState)

	return <Provider value={{ state, dispatch }}>{children}</Provider>;
}

