import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
	component: {
		width: '100%',
		minHeight: 180,
		justifyContent: 'space-between',
		display: 'flex',
		flexDirection: 'column'
	},
	content: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		marginBottom: '2.75rem'
	},
	label: {
		marginTop: '0.75rem',
		marginBottom: '1rem',
		fontSize: '1.0625rem',
		fontWeight: '400',
		lineHeight: '1.6',
	},
	form: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	action: {
		'& path': {
			fill: 'var(--color-primary)',
		}
	},
	disabledAction: {
		'& path': {
			fill: '#cccccc'
		}
	},
	value: {
		fontSize: '1.25rem',
		fontWeight: '600',
		minWidth: '4.5rem',
		textAlign: 'center'
	}
})
