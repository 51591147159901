import { firestoreService } from './firebase'

export function findRestaurantByHash(hash, result) {
  const menuCodes = firestoreService().collection("digital_menu_codes")
  menuCodes.where("hash", "==", hash)
    .get()
    .then((querySnapshot) => {
      const foundedMenuCodes = querySnapshot.docs
      if (foundedMenuCodes.length > 0) {
        const menuCodeData = foundedMenuCodes[0].data()
        result(menuCodeData)
      } else {
        result(null)
      }
    })
    .catch((error) => {
        result(null)
    });
}
