import { ActionBack } from './components/action-back'

import { useStyles } from './header.styles'

export function Header({ absolutely = false, onBack, renderOnRight = null }) {
	const classes = useStyles({ absolutely })

	return (
		<header className={classes.component}>
			<ActionBack onClickOrLocation onClick={onBack} />
			<div className={classes.right}>
				{renderOnRight}
			</div>
		</header>
	)
}
