import { useMemo } from 'react';
import '@brainhubeu/react-carousel/lib/style.css';
import Carousel from '@brainhubeu/react-carousel';

import { RestaurantMenuPremiumFeaturedCard } from './components/restaurant-menu-premium-featured-card'
import { useStyles } from "./restaurant-menu-premium-featured-group.styles"
import classNames from 'classnames';

export function RestaurantMenuPremiumFeaturedGroup({ section, suggestions }) {
	const classes = useStyles()

	const cardWidth = useMemo(() => {
		const _ = document.documentElement?.clientHeight || window.innerHeight

		if (_ > 414) {
			return 414
		}

		return _
	}, [])

	if (typeof section === "undefined" || section === null || section.length < 1) {
		return null
	}

	return (
		<div className={classNames(classes.component, { [classes.suggestions]: suggestions })}>
			{!suggestions && <h2 className={classes.title}>Destaques</h2>}
			{section.length === 1
				? <div className={classes.single}><RestaurantMenuPremiumFeaturedCard isSuggestion={suggestions} item={section[0]} /></div>
				: (
					<Carousel itemWidth={cardWidth}>
						{section.map(item => (
							<RestaurantMenuPremiumFeaturedCard isSuggestion={suggestions} item={item} key={item.id} />
						))}
					</Carousel>
				)}
		</div>
	)
}
