import classNames from 'classnames'
import React from 'react'
import { useCallback } from 'react'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useAppActions } from '../../store/app'
import { use100vh } from '../../hooks/mobile-height-hook'
import { dispatchStartItemList } from '../../services/firebase'
import { useCartContext } from '../../store/cart'
import { getCartQuantity, getCartItemNames, getCartQuantities, getCartPrices } from '../../utilities/cart-utilities'
import { IconToastHand } from '../icons'

import { useStyles } from './container.styles'

function GoToCartButton({ value }) {
	const classes = useStyles()
	const cart = useCartContext()
	const appActions = useAppActions()

	const handleToastOnClick = useCallback(() => {
		dispatchStartItemList(getCartQuantity(cart.state), getCartItemNames(cart.state), getCartQuantities(cart.state), getCartPrices(cart.state))
		appActions.goToCart()
	}, [cart.state, appActions])

	return (
		<Link onClick={handleToastOnClick} className={classes.goToCart}>
			<IconToastHand />
			<span>{value === 1 ? '1 item adicionado' : `${isNaN(value) ? '' : value} itens adicionados` }</span>
		</Link>
	)
}

export function Container({ children, centered, light = true, withCartFeedBack = false, ultra = false }) {
	const height = use100vh()
	const classes = useStyles({ centered, light, height })
	const cart = useCartContext()
	const _length = useMemo(() => getCartQuantity(cart.state), [cart])

	return (
		<section className={classNames(classes.component, {
			[classes.ultraComponent]: ultra
		})} id='app-container'>
			{children}
			{cart.state.length && withCartFeedBack ? <GoToCartButton value={_length} /> : null}
		</section>
	)
}
