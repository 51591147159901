import { useEffect, useCallback, useState, useMemo } from "react"
import { dispatchSelectSection } from "../services/firebase"
import { useMenuContext } from "../store/menu"
import { isObject } from "../utilities/objectUtils"
import { filterSectionsByFilterList } from '../utilities/sections/filter'


export function useMenu() {
	const menu = useMenuContext()
	const [ section, setSection ] = useState(0)
	const [ activedFilters, changeActivedFilters ] = useState([])
	const [filteredMenuSections, setFilteredMenuSections] = useState(menu.state.menuSections)

	const sections = useMemo(() => menu.state.menuSections, [menu.state.menuSections])

	useEffect(() => {
		const origin = isObject(section) ? [section] : sections
		const _items = filterSectionsByFilterList(origin, activedFilters)

		return setFilteredMenuSections(_items)
	}, [activedFilters, section, sections])

	const selectSection = useCallback((_, value) => {
		if (value === 0) {
			return setSection(0)
		}

		if (value <= sections.length) {
			return setSection(sections[value - 1])
		}
		dispatchSelectSection(value.name)
		return setSection(value)
	}, [sections])

	const filter = useCallback((value) => {
		if (value === null) {
			return changeActivedFilters([])
		}

		return changeActivedFilters(value)
	}, [])

	return {
		section,
		sections,
		activedFilters,
		filteredMenuSections,
		filter,
		selectSection,
	}
}
