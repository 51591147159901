const paths = {
	notFound: "/nao-encontrado",
	appError: "/erro-inesperado",
	index: "/",
	aboutChefsClub: "/sobre",
	cart: "/minha-lista",
	redirect: '/redirect'
};

export default paths;
