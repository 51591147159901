import styled from "styled-components";

export const Component = styled.div`
	@keyframes load-loader-component {
		from {
			opacity: 0;
			visibility: hidden;
			transform: translateY(-0.25rem);
		}
		to {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
		}
	}

	position: relative;
	margin: 4rem 0 0;
	display: flex;
	align-items: center;
	justify-content: center;
	animation: load-loader-component 0.75s ease-in-out;
	transition: 0.75s ease-in-out;

	${({ show }) =>
		!show &&
		`
		opacity: 0;
		visible: hidden;
		transform: translateY(-0.25rem);
		`}

	& > div {
		opacity: 1;
		width: 10rem;
		height: 2.5rem;
		filter: contrast(20);

		background-color: var(--color-${({ light }) => (light ? "black" : "white")});
	}
`;

export const Dot = styled.span`
	@keyframes dot {
		50% {
			transform: translateX(96px);
		}
	}

	position: absolute;
	width: 1rem;
	height: 1rem;
	top: 0.75rem;
	left: 1rem;
	filter: blur(4px);
	border-radius: 50%;
	transform: translateX(0);
	animation: dot 2.8s infinite;

	background-color: var(--color-${({ light }) => (!light ? "black" : "white")});
`;

export const Dots = styled.div`
	@keyframes dots {
		50% {
			transform: translateX(-31px);
		}
	}

	transform: translateX(0);
	margin-top: 0.75rem;
	margin-left: 2rem;
	animation: dots 2.8s infinite;

	& span {
		display: block;
		float: left;
		width: 1rem;
		height: 1rem;
		margin-left: 1rem;
		filter: blur(0.25rem);
		border-radius: 50%;

		background-color: var(--color-${({ light }) => (!light ? "black" : "white")});
	}
`;
