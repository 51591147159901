import { useStyles } from "./review-flag.styles"

export function ReviewFlag({ value }) {
	const classes = useStyles()

	if (!value || value <= 0) {
		return null
	}

	return (
		<span className={classes.component}>
			<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M2.89155 12.9654C3.1377 13.1575 3.4499 13.0915 3.82214 12.8213L6.99816 10.4858L10.1802 12.8213C10.5524 13.0915 10.8586 13.1575 11.1108 12.9654C11.3569 12.7793 11.411 12.4731 11.2609 12.0348L10.0061 8.30044L13.2121 5.99497C13.5843 5.73081 13.7344 5.45463 13.6384 5.15444C13.5423 4.86626 13.2601 4.72216 12.7978 4.72817L8.86535 4.75218L7.67059 0.999798C7.52649 0.555515 7.31036 0.333374 6.99816 0.333374C6.69196 0.333374 6.47583 0.555515 6.33173 0.999798L5.13697 4.75218L1.20447 4.72817C0.74218 4.72216 0.460001 4.86626 0.36394 5.15444C0.261875 5.45463 0.417974 5.73081 0.790211 5.99497L3.99625 8.30044L2.74145 12.0348C2.59136 12.4731 2.64539 12.7793 2.89155 12.9654Z" fill="#FFBB00"/>
			</svg>

			<span>{value.toFixed(1)}</span>
		</span>
	)
}
