import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyles = createGlobalStyle`
	${reset}

	* {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		--font-smoothing: antialiased;
	}

	*, *::before, *::after {
		box-sizing: border-box;
	}

	:root {
		--color-primary: black;
		--color-black: black;
		--color-black-light: #252525;
		--color-white: white;
		--color-disabled: rgba(249, 249, 249, 0.94);

		--color-secondary: #53565A;
		--color-secondary-light: #F5F5F5;
	}

	@media (prefers-color-scheme: dark) {
		--color-disabled: #333333;
	}

	::-webkit-scrollbar {
		width: 0.125rem;
		cursor: pointer;
	}

	::-webkit-scrollbar-track {
		background: var(--color-secondary-light);
	}

	::-webkit-scrollbar-thumb {
		background: var(--color-secondary);
	}

	::-webkit-scrollbar-thumb:hover {
		background: var(--color-primary);
	}

	html, body {
		font-family: 'Libre Franklin', sans-serif;
		font-weight: 400;
	}

	body {
		background-color: var(--color-disabled);
		overflow: hidden;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.BrainhubCarousel__container,
	.BrainhubCarousel,
	.BrainhubCarousel .BrainhubCarousel__trackContainer,
	.BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
		overflow: visible;
	}

	#app-entry {
		@media screen and (min-width: 640px) {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
`;
export default GlobalStyles;
