import React from 'react'

import { RestaurantPremiumMenuItem } from '../item'

import { useStyles } from './restaurant-menu-premium-section.styles'

export function RestaurantMenuPremiumSection({ section }) {
	const classes = useStyles()

	if (section.items.length === 0) {
		return <div />
	}

	return <section className={classes.component}>
		<h3 className={classes.title}>{section.name}</h3>
		{section.items.map(i => <RestaurantPremiumMenuItem item={i} key={i.id} />)}
	</section>
}
 