import { instance } from "./firebase-app-instance";

export function messagingService() {
	return instance.messaging()
}

export function requestMessagingPermission() {
	return new Promise((resolve, reject) => {
		messagingService()
			.requestPermission()
			.then(() => messagingService().getToken())
			.then(_token => {
				resolve(_token)
			})
			.catch(err => {
				console.error(err)
				reject(err)
			})
	})
}

export function messagingListener() {
	return new Promise((resolve) => {
		messagingService().onMessage(payload => resolve(payload))
	})
}
