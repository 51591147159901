import { CarouselMenuChefsClubItem } from './carousel-menu-chefsclub-item-component'

import { useStyles } from "./carousel-menu-item.styles"

export function CarouselMenuItem({ image, label, aboutChefs, onClick }) {
	const classes = useStyles({ image })

	if (aboutChefs === true) {
		return <CarouselMenuChefsClubItem onClick={onClick} />
	}

	return (
		<div onClick={onClick} className={classes.component}>
			<div className={classes.cover}></div>
			<h2 className={classes.label}>{label}</h2>
		</div>
	)
}
